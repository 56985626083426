<template>
	<div>
		<div class="foget">
			<div style="display: flex;justify-content: space-between;padding-bottom: 36px;">
				<div class="title">忘记密码</div>
				<div class="bottom">
					新用户请先注册
					<span class="black" @click="$router.push('/loginRegistration?loginStep=0&redirect='+$route.query.redirect)">立即注册 ></span>
				</div>
			</div>
			<a-form :form="form">
				<a-form-item :label="''">
					<a-input
						v-decorator="['mobile', { trigger: 'blur',rules: [{ required: true, message: '请输入手机号码'},{ message: '请输入手机号码',pattern:/^1[3456789]\d{9}$/ }] }]"
						@blur="handleGetImgCode"
						placeholder="手机号码"
					>
						<img slot="prefix" class="icon" src="@/assets/icon/phone.png"/>
					</a-input>
				</a-form-item>
				<a-form-item :label="''">
					<a-input-password
						v-decorator="['newPassword', {rules: [{ required: true, message: '请输入新密码!'},{message: '密码长度不能少于6个字符',min:6,max:18 },{
                    validator: validateToNextPassword,
                }] }]"
						:maxLength="18"
						placeholder="新密码"
						type="password"
					>
						<img slot="prefix" class="icon" src="@/assets/icon/password.png"/>
					</a-input-password>
				</a-form-item>
				<a-form-item :label="''">
					<a-input-password
						v-decorator="['confirmPassword', { rules: [{ required: true, message: '请确认新密码' },,{
                    validator: compareToFirstPassword,
                }] }]"
						:maxLength="18"
						placeholder="确认新密码"
						type="password"
						@blur="handleConfirmBlur"
					>
						<img slot="prefix" class="icon" src="@/assets/icon/password.png"/>
					</a-input-password>
				</a-form-item>
				<a-form-item>
					<ImgCode ref="imgCode" :usage="2"/>
				</a-form-item>
				<!--                <div class="inputPhone">
														<a-form-item :label="''">
																<a-input
																		placeholder="短信验证码"
																		v-decorator="['token', { trigger: 'blur',rules: [{ required: true, message: '请输入验证码'},{ message: '请输入验证码',pattern:/\d{4}$/ }] }]"
																>
																		<img src="@/assets/icon/captcha.png" class="icon" slot="prefix"/>
																</a-input>
																<GetSmsbutton :validateFields="form.validateFields" usage="2" ref="getSmsbutton"/>
														</a-form-item>
												</div>-->
				<a-form-item>
					<div class="input-sms-code">
						<a-input
							v-decorator="['token', {trigger: 'change', rules: [
                { required: true, message: '请输入验证码!' },{message: '请输入正确验证码!',pattern:/^\d{4}$/}] }]"
							placeholder="请输入手机验证码"
						>
							<img slot="prefix" class="icon" src="../../../assets/icon/captcha.png"/>
						</a-input>
						<a-button @click="sendSmsCode">{{ time ? `(${time}s)` : '获取验证码' }}</a-button>
					</div>
				</a-form-item>
			</a-form>
			<button class="default-btn" @click="submit">
				<a-icon v-show="loading" type="loading"/>
				找回
			</button>
			<div v-if="successfullyModified" class="modified-successfully">
				<img alt="" src="@/assets/user/true.png">
				<span>成功找回密码，即将跳转回登录页面……</span>
			</div>
		</div>
	</div>
</template>
<script>
import md5 from "blueimp-md5";
import BeforeLogin from "@/components/BeforeLogin.vue";
import GetSmsbutton from "@/components/getSmsbutton";
import {getMobileCode, restPassword} from "@/api/user"
import ImgCode from "@/views/loginRegistration/Register/ImgCode.vue";

export default {
	name: "forgetPassword",
	components: {BeforeLogin, GetSmsbutton, ImgCode},
	data() {
		return {
			form: this.$form.createForm(this, {name: "forget"}),
			loading: false,
			successfullyModified: false,
			time: 0
		};
	},
	computed: {
		onLine() {
			return !this.offline
		}
	},
	props: ["changeForm", "offline", "zjer"],
	mounted: function () {
		//localStorage.user = ''
		if (localStorage.userName) {
			this.userName = localStorage.userName;
			this.password = localStorage.password;
			this.remeber = true
		}
	},
	methods: {
		handleGetImgCode() {
			this.$nextTick(() => {
				this.form.validateFields(['mobile'], (error, value) => {
					if (!error) {
						// this.$refs['imgCode'].getImgCode(value)
					}
				})
			})
		},
		sendSmsCode() {
			if (this.time) return
			const imgCode = this.$refs['imgCode'].imgCode
			if (!imgCode || !/\d{4}/.test(imgCode)) {
				this.$message.warn('请输入图形验证码')
				return
			}
			this.form.validateFields(["mobile"], (err, value) => {
				if (!err) {
					getMobileCode({
						...value,
						usage: 1,
						explosionProof: true,
						captchaCode: imgCode
					})
						.then(() => {
							this.form.resetFields('smsCode')
							this.$message.success("发送成功");
							this.time = 60
							let a = setInterval(() => {
								this.time = this.time - 1
								if (!this.time) clearInterval(a)
							}, 1000);
						});
				}
			});
		},
		handleConfirmBlur(e) {
			const value = e.target.value;
			this.confirmDirty = this.confirmDirty || !!value;
		},
		compareToFirstPassword(rule, value, callback) {
			const form = this.form;
			if (value && value !== form.getFieldValue("newPassword")) {
				callback("两次输入的密码不一致");
			} else {
				callback();
			}
		},
		validateToNextPassword(rule, value, callback) {
			const form = this.form;
			if (value && this.confirmDirty) {
				form.validateFields(["confirmPassword"], {force: true});
			}
			callback();
		},
		submit() {
			if (this.loading) return
			this.form.validateFields((err, value) => {
				if (!err) {
					this.loading = true
					value.newPassword = md5(value.newPassword).toUpperCase();
					restPassword({...value}).then(() => {
						this.$message.success('修改成功！')
						this.loading = false
						this.successfullyModified = true
						setTimeout(() => {
							this.$router.push("/loginRegistration?loginStep=1&redirect=" + this.$route.query.redirect)
						}, 1500)
					}).catch(e => {
						this.loading = false
					});
				}
			});
		}
	},
};
</script>
<style lang="less" scoped>

/deep/ .ant-form-item-label {
	width: 80px;
	line-height: 48px;
	height: 48px;
	vertical-align: top;
}

/deep/ .ant-form-item-control-wrapper {
	display: inline-block;

	.ant-form-item-control {
		width: 336px;
	}

	input {
		height: 48px;
		width: 336px;
	}
}

.foget {
	display: flex;
	align-self: center;
	flex-direction: column;
	width: 336px;

	.title {
		font-size: 24px;
		color: #333333;
		letter-spacing: 0;
		line-height: 24px;
	}

	//
	///deep/ .inputPhone {
	//	.ant-input-affix-wrapper {
	//		width: 212px;
	//	}
	//
	//	button {
	//		width: 112px;
	//		height: 48px;
	//		font-size: 14px;
	//		color: #fff;
	//		background: #f6492b;
	//		margin-left: 12px;
	//	}
	//}

	/deep/ .input-sms-code {
		display: flex;

		.ant-input-affix-wrapper {
			width: 232px;

			input {
				width: 100%;
			}
		}

		button {
			width: 112px;
			height: 48px;
			font-size: 16px;
			color: #fff;
			text-align: center;
			margin-left: 12px;
			background-color: #f73210;
		}
	}

	.icon {
		width: 16px;
		height: 16px;
	}

	div.bottom {
		font-size: 16px;
		//margin-top: 40px;
		color: #666666;

		.black {
			cursor: pointer;
			font-size: 16px;
			color: #F6492B;
		}

		.right {
			float: right;
			text-decoration: underline;
			cursor: pointer;
		}
	}
}

.default-btn {
	&.login2 {
		border: 1px solid #f73210;
		background: white;
		color: #f73210;
		position: absolute;
		width: 336px;
		bottom: 150px;
	}
}

.default-btn {
	height: 48px;
	background: #f73210;
	border-radius: 4px;
	font-size: 20px;
	color: #ffffff;
	border: 0;
	cursor: pointer;
	-webkit-app-region: no-drag;
}

/deep/ #forget_token {
	width: 200px;
}

/deep/ .ant-form-item {
	//margin-bottom: 14px;
}

.modified-successfully {
	display: flex;
	align-items: center;
	padding: 10px 0px;
	font-size: 16px;
	color: #666666;


	img {
		width: 18px;
		height: 18px;
		margin-right: 5px;
	}
}

</style>
