<template>
  <div>
    <div class="container">
      <div class="loginRegistration">
        <div class="content">
          <div class="choice"  v-show="contentType == -1">
            <div class="choice-title">
              请选择处理方式
            </div>
            <div class="choice-main">
              <div class="choice-main-item"  @click="$router.push({ path: '/loginRegistration', query: {loginStep: 6,redirect:$route.query.redirect}})">
                <img src="@/assets/icon/mobile.svg" class="choice-main-item-icon">
                <div class="choice-main-item-name">手机验证码登录</div>
              </div>
              <div class="choice-main-item" @click="$router.push({ path: '/loginRegistration', query: {loginStep: 3,redirect:$route.query.redirect}})">
                <img src="@/assets/icon/password.svg" class="choice-main-item-icon">
                <div class="choice-main-item-name">找回密码</div>
              </div>
            </div>
          </div>

          <loginPanel v-if="contentType == 1" @toggleCompletePanel="toggleCompletePanel" @toggleLoginPanel="toggleContent"></loginPanel>
          <phoneLogin v-if="contentType == 6"  @toggleCompletePanel="toggleCompletePanel"></phoneLogin>
          <choice v-if="contentType == 0" @toggleLoginPanel="toggleContent"></choice>
          <!-- <registration-method></registration-method> -->
          <register v-if="contentType == 2" :role="roleIndex === 0 ? 'teacher' : 'student'"></register>
          <Complete v-if="contentType == 4" :role="role" :userId="userId" :schoolName="schoolName"> </Complete>
          <forgetPassword v-if="contentType == 3"></forgetPassword>
          <binding v-if="contentType == 5" :role="roleIndex === 0 ? 'teacher' : 'student'" @toggleCompletePanel="toggleCompletePanel"></binding>
          <bindChoice v-if="contentType == 7" :role="roleIndex === 0 ? 'teacher' : 'student'" @toggleCompletePanel="toggleCompletePanel"></bindChoice>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import loginPanel from './loginPanel.vue'
import phoneLogin from './phoneLogin.vue'
import register from './Register/register'
import binding from './Register/binding'
import bindChoice from './Register/bindChoice'
import choice from './Register/choice' //选择角色
import registrationMethod from './Register/registrationMethod' // 选择注册方式
import Complete from '../../components/CompleteV1'
import forgetPassword from '@/views/loginRegistration/Register/forgetPassword' // 忘记密码
import Footer from '@/components/Footer'
import {mapActions, mapMutations} from "vuex";
export default {
  components: {
    register,
    choice,
    registrationMethod,
    loginPanel,
    Complete,
    forgetPassword,
    binding,
    phoneLogin,
    Footer,
    bindChoice
  },
  data() {
    return {
      contentType: 1,
      roleIndex: 0,
      bg: require('@/assets/WisdomTeaching/ic_login_bg.jpg'),
      userId: undefined,
      role: '',
      schoolName: ''
    }
  },
	created() {
    if(this.$route.query.redirectUrl){
      sessionStorage.setItem('redirectUrl', this.$route.query.redirectUrl)
    }
		// 如果已经登录，就是直接跳转登录页
    if(localStorage.user){
      if(this.$route.query.redirectUrl){
        this.toClassServer({replace: true, redirectUrl:this.$route.query.redirectUrl})
      }else{

      
			// this.setUser(JSON.parse(localStorage.user));
			this.$router.push(this.$route.query.redirect || '/');
      }
		}
	},
	watch: {
    $route: {
      handler() {
        // console.log(this.$route.query.loginStep)
        this.contentType = this.$route.query.loginStep || 1
        if (this.$route.query.isTeacher) {
          this.roleIndex = this.$route.query.isTeacher === 'true' ? 0 : 1
        }
				if (this.$route.query.role) {
					this.role = [this.$route.query.role]
				}
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
		...mapMutations(["setUser"]),
    ...mapActions(['toClassServer']),
    toggleContent(contentType, roleIndex) {
      this.contentType = contentType
      this.roleIndex = roleIndex
    },
    toggleCompletePanel(userId, role, schoolName) {
      this.userId = userId
      this.role = role
      this.schoolName = schoolName
      this.toggleContent(4)
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  min-height: 600px;
  height: calc(~'100vh - 72px - 320px');
  margin: 30px auto;
  margin-top: -72px;
  display: flex;
  justify-content: center;
  align-items: center;
  .loginRegistration {
    position: relative;
    //  min-height: calc(100vh);
    width: 1200px;
    height: 600px;
    background: url('../../assets/WisdomTeaching/login.png') no-repeat center center;
    background-size: 1200px 600px;
    margin: 30px auto;
  }
  .content {
    position: absolute;
    padding: 30px;
    width: 400px;
    //   top: 20%;
    top: 50%;
    //   right: 5%;
    right: 0;
    transform: translateY(-50%);
    display: inline;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 32px -8px rgba(0, 0, 0, 0.15);

    .choice{
      &-title {
        font-size: 20px;
        color: #333333;
        text-align: center;
        line-height: 20px;
        font-weight: 600;
      }
      &-main {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-top: 32px;
        &-item {
          cursor: pointer;
          text-align: center;
          &-name {
            text-align: center;
            font-size: 16px;
            color: #333333;
            text-align: center;
            line-height: 16px;
            font-weight: 600;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
