<template>
  <div class="login">
    <div class="login-title">您的之江汇账号尚未绑定平台账号</div>
    <div class="login-btn" @click="$router.push({ path: '/loginRegistration', query: { loginStep: '5', isTeacher: $route.query.isTeacher } })">绑定已有账号</div>
    <div class="login-btn" @click="$router.push({ path: '/loginRegistration', query: { loginStep: '2', step: 1 } })">注册新账号</div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods:{

  }
};
</script>

<style lang="less" scoped>
.login {
  display: flex;
  align-self: center;
  flex-direction: column;

  width: 336px;
  padding: 24px 0;
  &-title {
    font-family: PingFangSC-Semibold;
    font-size: 20px;
    color: #333333;
    letter-spacing: 0;
    text-align: center;
    line-height: 20px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  &-btn {
    border: 1px solid rgba(246, 73, 43, 1);
    border-radius: 4px;

    font-family: PingFangSC-Semibold;
    font-size: 18px;
    color: #f6492b;
    letter-spacing: 0;
    text-align: center;
    line-height: 48px;
    font-weight: 600;
    cursor: pointer;
    &+& {
      margin-top: 20px;
    }
  }
}
</style>
