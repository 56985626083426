<template>
    <div class="login">
        <div class="title-panel">
            <span class="title">绑定账号</span>
            <span class="register-jumper">
                还没有账号？
                <span class="redWords" @click="toggleRegisterPanel">立即注册 ></span>
            </span>
        </div>

        <div class="error-tiper" v-if="loginFailed">手机号或者密码错误</div>

        <div style="position: relative;">
            <a-input size="large" placeholder="请输入手机号" v-model="userName" ref="userNameInput" @input="userNameInput">
                <img src="../../../assets/icon/phone.png" class="icon" slot="prefix" />
            </a-input>
            <p class="error" v-if="userNameError">手机号错误</p>
        </div>
        <div style="position: relative;">
            <a-input-password
                size="large"
                placeholder="请输入密码"
                type="password"
                v-model="password"
                ref="passwordInput"
                @pressEnter="bind"
                @input="passwordInput"
            >
            <img src="../../../assets/icon/password.png" class="icon" slot="prefix" />
            </a-input-password>
            <p class="error" v-if="passwordError">请输入密码</p>
        </div>
        <div class="center">
            <div class="right" @click='forgetPassword'>忘记密码</div>
        </div>

        <button class="default-btn" @click="bind">
            <span>绑定</span>
        </button>
    </div>
</template>

<script>
import md5 from "blueimp-md5";
import {login,getLoginUrl,completed,bindZjerUser} from "../../../api/user"
import { mapMutations,mapGetters} from "vuex";
export default {
    data() {
        return {
            userName: null,
            password: null,
            remeber: false,
            passwordError:false,
            userNameError:false,
            loading:false,
            loginFailed:false,
            src:'',
            userId: undefined,
            role: '',
            schoolName: ''
        };
    },
    props: {
        role: {
            type: String,
            default() {
                return "student"
            },
            require: false
        }
    },
    computed:{
        onLine(){
            return !this.offline
        },
      ...mapGetters(["getZjerParam"]),
    },
    props: ["changeForm","offline","zjer"],
    mounted: function() {
        //localStorage.user = ''
        if (localStorage.userName) {
        this.userName = localStorage.userName;
        this.password = localStorage.password;
        this.remeber = true
        }
    },
    methods: {
        ...mapMutations(["setUser"]),
        // 切换到注册面板
        toggleRegisterPanel() {
            // this.$emit('toggleLoginPanel', 0)
            this.$router.push({ path: '/loginRegistration', query: {loginStep: 2,redirect:this.$route.query.redirect}})
        },
        // 切换到忘记密码面板
        forgetPassword() {
            // this.$emit('toggleLoginPanel', 3)
            this.$router.push({ path: '/loginRegistration', query: {loginStep: 3,redirect:this.$route.query.redirect}})

        },
        userNameInput(e) {
            let value = e.target.value
            let reg = /^1[3456789]\d{9}$/
            this.userNameError = !reg.test(value)
        },
        passwordInput(e) {
            let value = e.target.value
            let reg = /^.{6,18}$/
            this.passwordError = !reg.test(value)
        },
        bind() {
            if(this.userNameError || this.passwordError || this.loading) return
            if (this.userName && this.password) {
                this.loading = true
                login({
                    userName: this.userName,
                    password: md5(this.password).toUpperCase(),
                }).then(res => {
                    if (res) {
                    localStorage.lastUser = JSON.stringify({
                        userName: this.userName,
                        password: this.password
                    })
                    localStorage.user = JSON.stringify(res.data);
                    this.setUser(res.data);
                    if (this.remeber) {
                        localStorage.userName = this.userName;
                        localStorage.password = this.password;
                    } else {
                        localStorage.userName = "";
                        localStorage.password = "";
                    }
                    this.userId = res.data.userId
                    console.log(this.getZjerParam)
                    bindZjerUser({
                        oauthCode: this.getZjerParam.oauthCode,
                        personId: this.getZjerParam.personId,
                        userId: res.data.userId,
                    }).then(res => {
                      this.isImproveInformation(this.userId)
                    }).catch(e => {
                        this.loading = false
                        this.loginFailed = true
                    });
                    }
                }).catch(e => {
                    console.log(e);
                    this.loading = false
                    this.loginFailed = true
                });
            }
        },
			// 判断是否完善信息
			async isImproveInformation(userId) {
				const res = await completed(userId)
				if (res.data) {
					await this.$router.push(this.$route.query.redirect||'/');
				} else {
					this.$emit('toggleCompletePanel', this.userId, this.role, this.schoolName)
				}
			}
    }
}
</script>

<style lang="less" scoped>
    .log {
        height: calc(~"100% - 80px");
        display: flex;
        &_logo {
            width: 730px;
            height: 100%;
            background: url("../../../assets/login_img@2x.png");
            background-size: 100% 100%;
        }
        /deep/ .ant-input {
            height: 48px;
        }
    }
    .completed {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
        width: 173px;
        height: 173px;
        }
        p {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
        }
        .default-btn {
        width: 224px;
        margin-top: 40px;
        }
    }
    .login {
        display: flex;
        align-self: center;
        flex-direction: column;
        width: 336px;
        .title-panel {
            display: flex;
            justify-content: space-between;
            .title {
            font-family: PingFangSC-Semibold;
            font-size: 24px;
            color: #333333;
            letter-spacing: 0;
            line-height: 24px;
            }
            .register-jumper {
                font-family: PingFangSC-Regular;
                font-size: 16px;
                color: #666666;
                letter-spacing: 0;
                .redWords {
                    color: #f73210;
                    cursor: pointer;
                }
            }
        }

        .error-tiper {
            background: #f5ccc5;
            font-family: PingFangSC-Regular;
            font-size: 16px;
            color: #F6492B;
            letter-spacing: 0;
            line-height: 16px;
            padding: 8px;
            margin-top: 32px;
        }
        .icon {
        width: 16px;
        height: 16px;
        }
        /deep/ .ant-input-affix-wrapper {
        margin-bottom: 12px;
        margin-top: 24px;
        }
        .error {
        font-size: 12px;
        color: #ed4014;
        position: absolute;
        }
        .center {
        margin-bottom: 10px;
        .right {
            float: right;
            cursor: pointer;
        }
        /deep/ .ant-checkbox-checked .ant-checkbox-inner {
            background-color: #f73210;
            border-color: #f73210;
        }
    }
        p.bottom {
            font-size: 16px;
            margin-top: 40px;
            color: rgba(0, 0, 0, 0.45);
            .black {
            color: rgba(0, 0, 0, 0.65);
            cursor: pointer;
            font-weight: 600;
        }
        .right {
            float: right;
            text-decoration: underline;
            cursor: pointer;
        }
        }
    }
    input {
        height: 48px;
        width: 336px;
    }
    .default-btn{
        &.login2{
            border:1px solid #f73210;
            background: white;
            color: #f73210;
            // position: absolute;
            width: 360px;
            margin-top: 30px;
        }
    }
    .default-btn {
        height: 48px;
        background: #f73210;
        border-radius: 4px;
        font-size: 20px;
        color: #ffffff;
        border: 0;
        cursor: pointer;
        -webkit-app-region: no-drag;
    }
    /deep/.ant-input {
        height: 48px;
    }

</style>
