<template>
    <div class="Method">
        <p>
            <span>
                {{ registeMethod.name }}注册
            </span>
            <span>
                我是{{ registeMethod.name === '老师' ? '学生' : '老师' }}
            </span>
        </p>
        <p v-if="registeMethod.name === '老师'">认证老师可享受免费资源</p>
        <a-button class="btnred"  shape="round" size="large">之江汇账号认证</a-button>
        <a-button class="btnborderred" type="danger" ghost>没有之江汇账号</a-button>
        <p>已有账号？<span>立即登陆</span></p>
    </div>
</template>

<script>
export default {
    data () {
        return {
            registeMethod:{
                name: '老师'
            }
        }
    }
}
</script>

<style lang="less" scoped>
  .Method{
      >p:nth-child(1){
        display: flex;
        justify-content: space-between;
        vertical-align:middle;
        >span:nth-child(1){
            font-weight: bold;
            font-size: 23px;
        }
      }
      .btnred {
        background: #F6492B !important;
        width: 100%;
        height: 46px;
        border-radius: 4px;
        border: 1px solid  #F6492B;
        font-size: 18px;
        margin: 20px 0 20px 0;
        color: #FFFFFF;
        &:hover{
            background: #E23112 100% !important;
            border: 1px solid  #E23112;
        }
      }
      .btnborderred{
          width: 100%;
          height: 46px;
          font-size: 18px;
          margin: 20px 0 20px 0;
      }
      >p:nth-child(5){
          margin: 20px 0 20px 0;
          font-size: 16px;
          >span{
              color: red;
              cursor: pointer;
          }
      }
  }
</style>