<template>
  <div class="login">
    <div class="title-panel">
      <span class="title">登录</span>
      <span class="register-jumper">
        还没有账号？
        <!-- <span class="redWords" @click="$router.push('/register')">立即注册 ></span> -->
        <span class="redWords" @click="toggleRegisterPanel">立即注册 ></span>
      </span>
    </div>

    <div class="error-tiper" v-if="loginFailed">手机号或者密码错误</div>

    <div style="position: relative;">
      <a-input size="large" placeholder="请输入手机号" v-model="userName" ref="userNameInput" @input="userNameInput">
        <img src="../../assets/icon/phone.png" class="icon" slot="prefix" />
      </a-input>
      <p class="error" v-if="userNameError">手机号错误</p>
    </div>
    <div style="position: relative;">
      <ImgCode ref="imgCode" :usage="2"></ImgCode>
    </div>

    <div class="input-sms-code">
      <a-input v-model="smsCode" v-decorator="['smsCode', {
        trigger: 'change', rules: [
          { required: true, message: '请输入验证码!' }, { message: '请输入正确验证码!', pattern: /^\d{4}$/ }]
      }]"
        placeholder="请输入手机验证码">
        <img slot="prefix" class="icon" src="../../assets/icon/captcha.png" />
      </a-input>
      <a-button @click="sendSmsCode">{{ time ? `(${time}s)` : '获取验证码' }}</a-button>
    </div>

    <button class="default-btn" @click="login">
      <a-icon type="loading" v-show="loading" />
      <span v-show="!onLine">离线登录</span>
      <span v-show="onLine">登录</span>
    </button>

    <a-modal :visible="visible" :footer="null" :closable="false" :width="392">
      <div class="completed">
        <img src="../../assets/img_login_message.png" />
        <p>您的个人信息不完善，请先完善个人信息。</p>
        <!-- <button class="default-btn" @click="$router.push('complete')">确定</button> -->
        <button class="default-btn" @click="toggleCompletePanel">确定</button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import ImgCode from "@/views/loginRegistration/Register/ImgCode.vue";
import md5 from "blueimp-md5";
import { login, getLoginUrl, completed, getMobileCodeV2 } from "../../api/user"
import { mapMutations } from "vuex";
import Cookies from 'js-cookie'
export default {
  components: { ImgCode },
  mounted() {
  },
  data() {
    return {
      time: 0,
      imgSrc: '',
      imgCode: undefined,
      userName: null,
      password: null,
      remeber: false,
      visible: false,
      passwordError: false,
      userNameError: false,
      loading: false,
      loginFailed: false,
      src: '',
      userId: undefined,
      role: '',
      schoolName: '',
      smsCode: ''
    };
  },
  computed: {
    onLine() {
      return !this.offline
    }
  },
  props: ["changeForm", "offline", "zjer"],
  mounted: function () {
    //localStorage.user = ''
    if (localStorage.userName) {
      this.userName = localStorage.userName;
      this.password = localStorage.password;
      this.remeber = true
    }
  },
  methods: {
    ...mapMutations(["setUser"]),
    // 切换到完善信息面板
    toggleCompletePanel() {
      this.$emit('toggleCompletePanel', this.userId, this.role, this.schoolName)
      this.visible = false
    },
    // 切换到注册面板
    toggleRegisterPanel() {
      // this.$emit('toggleLoginPanel', 0)
      this.$router.push({ path: '/loginRegistration', query: { loginStep: 0, redirect: this.$route.query.redirect } })
    },
    // 切换到忘记密码面板
    forgetPassword() {
      // this.$emit('toggleLoginPanel', 3)
      this.$router.push({ path: '/loginRegistration', query: { loginStep: -1, redirect: this.$route.query.redirect } })

    },
    userNameInput(e) {
      let value = e.target.value
      let reg = /^1[3456789]\d{9}$/
      this.userNameError = !reg.test(value)
    },
    passwordInput(e) {
      let value = e.target.value
      let reg = /^.{6,18}$/
      this.passwordError = !reg.test(value)
    },
    login() {
      if (this.userNameError || this.passwordError || this.loading) return
      if (this.userName && this.smsCode) {
        this.loading = true
        login({
          loginType: 4, //loginType： 登录类型，1-登陆名 2-身份证 3-手机号 4-手机号短信 8-之江汇授权
          userName: this.userName,
          smsCode: this.smsCode,
        })
          .then(res => {
            if (res) {
              // localStorage.lastUser = JSON.stringify({
              //   userName: this.userName,
              //   password: this.password
              // })
              localStorage.user = JSON.stringify(res.data);
              this.setUser(res.data);
              Cookies.set('commonName', localStorage.user, { expires: 7, domain: 'zjeav.com' })

              this.userId = res.data.userId
              this.role = res.data.roles[0]
              this.schoolName = res.data.schoolName
              completed(res.data.userId).then(res => {
                if (!res.data) {
                  this.visible = true;
                } else {
                  // this.$router.push("/");
                  this.$router.push(this.$route.query.redirect || '/');
                }
              }).catch(e => {
                this.loading = false
                this.loginFailed = true
              });
            }
          })
          .catch(e => {
            this.loading = false
            this.loginFailed = true
          });
      }
    },
    login2() {
      this.webview = document.querySelector('webview');
      getLoginUrl().then((res) => {
        location.href = res.data;
      })
    },
    sendSmsCode() {
      if (this.time) return
      const imgCode = this.$refs['imgCode'].imgCode
      if (!imgCode || !/\d{4}/.test(imgCode)) {
        this.$message.warn('请输入图形验证码')
        return
      }
      if (!this.userNameError && this.userName) {
        getMobileCodeV2({
          mobile: this.userName,
          usage: 4,
          explosionProof: true,
          captchaCode: imgCode,
          platform: 'WEB'
        })
          .then(() => {
            this.$message.success("发送成功");
            this.time = 60
            let a = setInterval(() => {
              this.time = this.time - 1
              if (!this.time) clearInterval(a)
            }, 1000);
          });
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .input-sms-code {
  display: flex;
  margin-top: 24px;

  .ant-input-affix-wrapper {
    width: 232px;

    input {
      width: 100%;
    }
  }

  button {
    width: 112px;
    height: 48px;
    font-size: 16px;
    color: #fff;
    text-align: center;
    margin-left: 12px;
    background-color: #f73210;
  }
}

.log {
  height: calc(~"100% - 80px");
  display: flex;

  &_logo {
    width: 730px;
    height: 100%;
    background: url("../../assets/login_img@2x.png");
    background-size: 100% 100%;
  }

  /deep/ .ant-input {
    height: 48px;
  }
}

.completed {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 173px;
    height: 173px;
  }

  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
  }

  .default-btn {
    width: 224px;
    margin-top: 40px;
  }
}

.login {
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 336px;

  .title-panel {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;

    .title {
      font-family: PingFangSC-Semibold;
      font-size: 24px;
      color: #333333;
      letter-spacing: 0;
      line-height: 24px;
    }

    .register-jumper {
      font-family: PingFangSC-Regular;
      font-size: 16px;
      color: #666666;
      letter-spacing: 0;

      .redWords {
        color: #f73210;
        cursor: pointer;
      }
    }
  }

  .error-tiper {
    background: #f5ccc5;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    color: #F6492B;
    letter-spacing: 0;
    line-height: 16px;
    padding: 8px;
    margin-top: 32px;
  }

  .icon {
    width: 16px;
    height: 16px;
  }

  /deep/ .ant-input-affix-wrapper {
    margin-bottom: 24px;
  }

  .error {
    font-size: 12px;
    color: #ed4014;
    position: absolute;
  }

  .center {
    margin-bottom: 10px;

    .right {
      float: right;
      cursor: pointer;
    }

    /deep/ .ant-checkbox-checked .ant-checkbox-inner {
      background-color: #f73210;
      border-color: #f73210;
    }
  }

  p.bottom {
    font-size: 16px;
    margin-top: 40px;
    color: rgba(0, 0, 0, 0.45);

    .black {
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
      font-weight: 600;
    }

    .right {
      float: right;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.default-btn {
  &.login2 {
    border: 1px solid #f73210;
    background: white;
    color: #f73210;
    // position: absolute;
    width: 336px;
    margin-top: 30px;
  }
}

.default-btn {
  height: 48px;
  background: #f73210;
  border-radius: 4px;
  font-size: 20px;
  color: #ffffff;
  border: 0;
  cursor: pointer;
  -webkit-app-region: no-drag;
}

/deep/.ant-input {
  height: 48px;
}</style>
