<template>
    <div class="choice" :class="{'choice--disabled':!agree}">
        <p>
        您是
        <span>
            老师
        </span>
        还是
        <span>
            学生
        </span>
        ?
        </p>
        <div class="role">
            <div v-for="(item,index) in roleList" :key="index" @click="selectRole(index)">
                <img :src="item.picture" alt="">
                <p class="role-title">{{ item.item }}</p>
            </div>
        </div>
        <div v-if="false" class="checkbox" @click="agree=!agree">
            <img src="@/icons/svg/sel_pc.svg" v-show="agree">
            <img src="@/icons/svg/unsel_pc.svg" v-show="!agree">
            我已阅读并同意<a @click.stop href="./user.pdf" target="_black">《用户须知》</a>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            agree:true,
            roleList:[
                {
                    picture: require('../../../assets/WisdomTeaching/ic_zhjx_teacher.png'),
                    item: '老师'
                },
                {
                    picture: require('../../../assets/WisdomTeaching/ic_zhjx_student.png'),
                    item: '学生'
                }
            ]
        }
    },
    methods: {
        selectRole(roleIndex) {
            // console.log('roleIndex:', roleIndex)
            this.$emit('toggleLoginPanel', 2, roleIndex)
        },
        onChange(e){
          console.log('onChange')
          this.agree = e.target.checked
        }
    }
}
</script>

<style lang="less" scoped>
 .choice{

    .checkbox {
      margin-top: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 16px;
      color: #666666;
      img {
        margin-right: 8px;
      }
      a {
        color: #F6492B;
      }
    }
    &--disabled{
      .role >div {
        pointer-events: none;
      }
      .role img {
        opacity: 0.3;
      }
    }
     .role{
         margin-top: 32px;
         display: flex;
         justify-content: space-around;
         >div{
             cursor: pointer;
             height: 120px;
             text-align: center;
             font-weight: bold;
             img{
                 width: 80px;
                 height: 80px;
             }
         }
         .role-title {
                font-family: PingFangSC-Semibold;
                font-size: 16px;
                color: #333333;
                letter-spacing: 0;
                text-align: center;
                line-height: 16px;
                margin-top: 16px;
         }
     }
     >p{
         text-align: center;
         font-weight: bold;

         >span{
             color: red;
         }
     }
 }
</style>
