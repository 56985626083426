var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foget"},[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.role === 'student' && _vm.step !== 3),expression:"role === 'student' && step !== 3"}],staticClass:"title"},[_vm._v(" 学生注册 "),(_vm.step !== 3)?_c('span',{staticClass:"teacher-register",on:{"click":function($event){_vm.role = 'teacher'}}},[_vm._v("老师注册 "),_c('a-icon',{staticStyle:{"color":"#f73210"},attrs:{"type":"right-circle"}})],1):_vm._e()]),_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.role === 'teacher' && _vm.step !== 3),expression:"role === 'teacher' && step !== 3"}],staticClass:"title"},[_vm._v(" 老师注册 "),(_vm.step!== 3 && _vm.step!== 0)?_c('span',{staticClass:"teacher-register",on:{"click":function($event){_vm.role = 'student'}}},[_vm._v("学生注册 "),_c('a-icon',{staticStyle:{"color":"#f73210"},attrs:{"type":"right-circle"}})],1):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.step === 0),expression:"step === 0"}],staticClass:"teacher-attestation"},[(_vm.role === 'teacher')?_c('div',{staticClass:"message"},[_vm._v("认证老师可享受免费资源")]):_vm._e(),_c('div',{staticClass:"button",on:{"click":_vm.toZjerLogin}},[_vm._v("之江汇认证")]),_c('div',{staticClass:"button-info",on:{"click":function($event){_vm.step = 1}}},[_vm._v("没有之江汇帐号")])]),(_vm.step === 1)?[_c('a-form',{attrs:{"form":_vm.form}},[_c('a-form-item',[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'mobile',
              {
                trigger: 'blur',
                rules: [
                  { required: true, message: '请输入手机号!' },
                  {
                    message: '请输入正确手机号!',
                    pattern: /^1[3456789]\d{9}$/,
                  },
                ],
              },
            ]),expression:"[\n              'mobile',\n              {\n                trigger: 'blur',\n                rules: [\n                  { required: true, message: '请输入手机号!' },\n                  {\n                    message: '请输入正确手机号!',\n                    pattern: /^1[3456789]\\d{9}$/,\n                  },\n                ],\n              },\n            ]"}],attrs:{"size":"large","placeholder":"请输入手机号"},on:{"blur":_vm.handleGetImgCode}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../../../assets/icon/phone.png")},slot:"prefix"})])],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'logonPassword',
            {
              rules: [
                { required: true, message: '请输入登录密码!' },
                { message: '请设置6-18位登录密码!', min: 6, max: 18 },
                {
                  validator: _vm.validateToNextPassword,
                },
              ],
            },
          ]),expression:"[\n            'logonPassword',\n            {\n              rules: [\n                { required: true, message: '请输入登录密码!' },\n                { message: '请设置6-18位登录密码!', min: 6, max: 18 },\n                {\n                  validator: validateToNextPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"设置6-18位登录密码","type":"password"}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../../../assets/icon/password.png")},slot:"prefix"})])],1),_c('a-form-item',[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'confirmPassword',
            {
              rules: [
                { required: true, message: '请再次输入密码!' },
                {
                  validator: _vm.compareToFirstPassword,
                },
              ],
            },
          ]),expression:"[\n            'confirmPassword',\n            {\n              rules: [\n                { required: true, message: '请再次输入密码!' },\n                {\n                  validator: compareToFirstPassword,\n                },\n              ],\n            },\n          ]"}],attrs:{"size":"large","placeholder":"请再次输入密码","type":"password"},on:{"blur":_vm.handleConfirmBlur}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../../../assets/icon/password.png")},slot:"prefix"})])],1),_c('a-form-item',[_c('ImgCode',{ref:"imgCode",attrs:{"usage":1}})],1),_c('a-form-item',[_c('div',{staticClass:"input-sms-code"},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['smsCode', {trigger: 'change', rules: [
            { required: true, message: '请输入验证码!' },{message: '请输入正确验证码!',pattern:/^\d{4}$/}] }]),expression:"['smsCode', {trigger: 'change', rules: [\n            { required: true, message: '请输入验证码!' },{message: '请输入正确验证码!',pattern:/^\\d{4}$/}] }]"}],attrs:{"placeholder":"请输入手机验证码"}},[_c('img',{staticClass:"icon",attrs:{"slot":"prefix","src":require("../../../assets/icon/captcha.png")},slot:"prefix"})]),_c('a-button',{on:{"click":_vm.sendSmsCode}},[_vm._v(_vm._s(_vm.time ? `(${_vm.time}s)` : '获取验证码'))])],1)])],1),_c('button',{staticClass:"default-btn",on:{"click":_vm.submit}},[_c('a-icon',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"type":"loading"}}),_vm._v("注册 ")],1)]:_vm._e(),(_vm.step === 3)?_c('Complete',{attrs:{"role":_vm.role,"userId":_vm.userId,"schoolName":_vm.schoolName}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }